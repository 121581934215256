import React from 'react';
import {
  DataGridPro,
  GridCallbackDetails,
  gridClasses,
  GridColDef,
  GridColumnMenuProps,
  GridEventListener,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getDetails, updateSeletedRows } from '../../store/slices/detailsSlice';
import { colors } from '../../assets/colors';
import CustomSortIcon from '../common/custom-sort-icon';
import { FlexBox } from '../common/wrappers';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import CustomColumnMenu from './CustomColumnMenu';
import { Option } from '../../interface';
import Typography from '../typography';
import { PaginationLoader } from '../../assets/icons';

interface Props {
  rowData: any[];
  columns: GridColDef[];
  showCheckBox?: boolean;
  apiRef?: React.MutableRefObject<GridApiPro>;
  rowHeight?: number;
  rowClickHandler?: GridEventListener<'rowClick'> | undefined;
  totalRows?: number;
  filterOptionsByfieldName: { [field: string]: Option[] };
  selectedFilters: {
    [field: string]: string[];
  };
  onFilterApply: (field: string, selectedFilters: string[]) => void;
  handleRowsScrollEnd?: () => void;
  isLoading: boolean;
  sortModel?: GridSortItem;
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails<any>,
  ) => void;
}

const MuiDataTableV2: React.FC<Props> = ({
  columns,
  rowData,
  apiRef,
  rowClickHandler,
  rowHeight,
  showCheckBox = true,
  totalRows,
  onFilterApply,
  selectedFilters,
  filterOptionsByfieldName,
  handleRowsScrollEnd,
  isLoading,
  onSortModelChange,
}) => {
  const { selectedRows } = useAppSelector(getDetails);
  const dispatch = useAppDispatch();

  const CustomFooter = () =>
    totalRows ? (
      <FlexBox className="table-footer">
        <Typography
          text={` Showing: ${rowData.length} of ${totalRows}`}
          fontSize="0.8rem"
          secondaryColorValue={600}
        />
        {isLoading && <img src={PaginationLoader} alt="loader" />}
      </FlexBox>
    ) : (
      <></>
    );

  return (
    <StyledWrapper className="show-no-scrollbar">
      <DataGridPro
        columns={columns}
        rows={rowData}
        apiRef={apiRef}
        rowHeight={rowHeight}
        checkboxSelection={showCheckBox}
        rowSelectionModel={selectedRows}
        onRowClick={rowClickHandler}
        rowCount={1}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(selectedRowsId) => {
          dispatch(updateSeletedRows(selectedRowsId));
        }}
        sortingMode="server"
        sortingOrder={['asc', 'desc', null]}
        onSortModelChange={onSortModelChange}
        slots={{
          columnSortedAscendingIcon: CustomSortIcon,
          columnSortedDescendingIcon: () => (
            <CustomSortIcon isDescending={true} />
          ),
          footer: CustomFooter,
          columnMenuIcon: FilterIcon,
          columnMenu: (props: GridColumnMenuProps) => (
            <CustomColumnMenu
              {...props}
              options={filterOptionsByfieldName[props.colDef.field] || []}
              selectedFilters={selectedFilters[props.colDef.field] || []}
              onApply={(filters) => onFilterApply(props.colDef.field, filters)}
            />
          ),
        }}
        onRowsScrollEnd={() => {
          handleRowsScrollEnd && handleRowsScrollEnd();
        }}
        sx={{
          fontFamily: 'Satoshi Medium',

          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            userSelect: 'none',
            backgroundColor: colors.grey[100],
            '& .MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
          },

          /** make action button visible on row hover */
          [`& .${gridClasses.row}:hover`]: {
            backgroundColor: 'white',
            cursor: 'pointer',
            '.action-buttons': {
              visibility: 'visible',
            },
          },
        }}
      />
    </StyledWrapper>
  );
};

export default MuiDataTableV2;

const StyledWrapper = styled.div`
  height: inherit;
  max-height: 850px;

  .MuiDataGrid-root {
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid ${colors.grey[50]};
  }

  .MuiDataGrid-root ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .MuiDataGrid-root ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #cccccc;
  }

  .MuiDataGrid-root ::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }

  /* Focus disable in column header */
  .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  .MuiCheckbox-root svg {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }

  .MuiCheckbox-root svg path {
    display: none;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
    box-shadow: 0px 0px 0px 2px rgba(85, 164, 242, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }

  .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate)::after {
    position: absolute;
    content: '';
    left: 14px;
    top: 11px;
    display: block;
    width: 4px;
    height: 8px;
    border: ${({ theme }) => `2px solid #2E8AE5`};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .MuiCheckbox-root.Mui-checked.MuiCheckbox-indeterminate svg {
    box-shadow: 0px 0px 0px 2px rgba(85, 164, 242, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #2e8ae5;
  }

  .MuiCheckbox-root.Mui-checked.MuiCheckbox-indeterminate::after {
    position: absolute;
    content: '';
    left: 12px;
    display: block;
    width: 8px;
    height: 0px;
    border: ${({ theme }) => `2px solid #2E8AE5`};
    border-width: 0 2px 2px 0;
    transform: none;
  }

  .MuiDataGrid-columnHeaders .MuiDataGrid-sortIcon {
    opacity: 1 !important;
  }

  .MuiDataGrid-menuIcon {
    width: unset !important;
    visibility: visible !important;
  }

  .table-footer {
    color: ${colors.grey[600]};
    padding: 0.7rem;
    font-size: 0.8rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid ${colors.grey[50]};
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .MuiDataGrid-menu .MuiPaper-root {
    border-radius: 0.5rem !important;
  }
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .action-btn-header .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }
`;
