import { GridColDef } from '@mui/x-data-grid-pro';

export type CustomGridColDef = GridColDef & {
  metadata?: {
    sortBy: string;
  };
};

export const columns: CustomGridColDef = {
  field: '',
  headerName: '',
  disableReorder: true,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};
