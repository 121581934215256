import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/colors';

interface Props {
  appearance: 'primary' | 'secondary' | 'danger';
  onClick: (e: React.SyntheticEvent) => void;
  label: string;
  icon?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | 'fullWidth';
  height?: string;
  className?: string;
  iconPlacement?: 'right' | 'left';
  setShowMessage?: (state: boolean) => void;
}

const Button: React.FC<Props> = ({
  onClick,
  appearance,
  disabled,
  label,
  icon,
  size,
  height,
  className,
  iconPlacement = 'left',
  setShowMessage,
  ...props
}) => {
  return (
    <StyledButton
      onClick={(e) => onClick(e)}
      appearance={appearance}
      disabled={disabled}
      size={size}
      className={className}
      onMouseOver={() => (setShowMessage ? setShowMessage(true) : '')}
      onMouseLeave={() => (setShowMessage ? setShowMessage(false) : '')}
      {...props}
    >
      {iconPlacement === 'left' && icon && (
        <div>
          <img width="20" src={icon} alt={label} />
        </div>
      )}
      <div>{label}</div>
      {iconPlacement === 'right' && icon && (
        <div>
          <img width="20" src={icon} alt={label} />
        </div>
      )}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button<{
  appearance: string;
  disabled?: boolean;
  size?: string;
  height?: string;
}>`
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.size === 'fullWidth' ? '100%' : 'auto')};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  margin: 0.4rem 0;
  padding: ${(props) =>
    props.size === 'small'
      ? '6px 8px'
      : props.size === 'medium'
      ? '6px 12px'
      : '8px 12px'};
  gap: 0.5rem;
  color: ${(props) =>
    props.appearance === 'secondary' ? colors.grey[800] : 'white'};
  background-color: ${(props) =>
    props.appearance === 'primary'
      ? colors.blue[800]
      : props.appearance === 'danger'
      ? colors.red[600]
      : 'white'};
  border: none;
  box-shadow: ${(props) =>
    props.appearance === 'secondary'
      ? colors.shadows.elevation1
      : props.appearance === 'danger' && colors.shadows.elevation4};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
