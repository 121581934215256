/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Header from './header';
import styled from 'styled-components';
import MyProductsTable from './my-product-table';
import { useGetMyProductsQuery } from '../../store/services/products';
import { getCutomizedMyProductsResponse } from '../../helper/getCutomizedMyProductsResponse';
import { useDebounce } from '../../hooks/useDebounce';
import { ProductsFilters } from '../../interface';
import Loader from '../common/loader';
import { TabletProps } from '../../interface';
import { FlexBox } from '../common/wrappers';
import { ColumnMenuFilterOptions } from '../../interface/product-interface';

const MyProducts: React.FC<TabletProps> = ({ isTablet }) => {
  const [filters, setFilters] = useState<ProductsFilters>({
    search: '',
    sortBy: {
      field: 'updatedAt',
      sort: 'desc',
    },
    status: '',
  });

  const [filterOptions, setFilterOptions] = useState<ColumnMenuFilterOptions>(
    {},
  );

  const [selectedFilterOptions, setSelectedFilterOptions] = useState<{
    [field: string]: string[];
  }>({});

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [rowData, setRowData] = useState<any[]>([]);

  const debouncedValue = useDebounce(filters.search, 500);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedValue]);

  const {
    data: myProducts,
    isFetching,
    isLoading,
    refetch,
  } = useGetMyProductsQuery({
    search: debouncedValue,
    brands: (selectedFilterOptions['brands'] || [])?.map((brand) => {
      if (brand.includes('+')) {
        return brand.replace('+', '%2B');
      } else return brand;
    }),
    printProviders: selectedFilterOptions['printProviders'] || [],
    status: filters.status,
    sortBy: filters.sortBy,
    page: currentPage,
  });

  useMemo(() => {
    if (myProducts) {
      setTotalPages(myProducts?.totalPages || 1);
      const printProviders = myProducts.printProviders.map((p) => ({
        label: p.name,
        value: p._id,
      }));
      const brands = myProducts.brands.map((brand) => ({
        label: brand,
        value: brand,
      }));

      setFilterOptions({ printProviders, brands });
      const data = getCutomizedMyProductsResponse(myProducts.results || []);

      setRowData((prev) =>
        myProducts.page === 1 ? [...data] : [...prev, ...data],
      );
    }
  }, [myProducts]);

  return (
    <StyleWrapper className="show-no-scrollbar">
      {(isLoading || (isFetching && currentPage === 1)) && <Loader />}
      <FlexBox direction="column" className="main-wrapper">
        <Header
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
          isTablet={isTablet}
        />
        <MyProductsTable
          rowData={rowData || []}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
          refetchProduct={refetch}
          isTablet={isTablet}
          totalRows={myProducts?.totalResults}
          setSelectedFilterOptions={setSelectedFilterOptions}
          selectedFilterOptions={selectedFilterOptions}
          filterOptions={filterOptions}
          isLoading={isFetching}
          setFilters={setFilters}
          filters={filters}
        />
      </FlexBox>
    </StyleWrapper>
  );
};

export default MyProducts;

const StyleWrapper = styled.div`
  padding: 33px 2rem;
`;
