import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { Option } from '../../interface';
import { colors } from '../../assets/colors';
import styled, { css } from 'styled-components';
import Typography from '../typography';

interface Prop {
  selectedValue: any;
  setSelectedValue: (value: string | null) => void;
  options: Option[];
  isTablet?: boolean;
}

const MuiToggleButton: React.FC<Prop> = ({
  selectedValue,
  setSelectedValue,
  options,
  isTablet = false,
}) => {
  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    e.stopPropagation();
    setSelectedValue(newValue);
  };

  return (
    <StyledWrapper isTablet={isTablet}>
      <ToggleButtonGroup
        value={selectedValue}
        onChange={handleChange}
        size="small"
        exclusive={true}
        fullWidth={isTablet}
      >
        {options.map((option) => (
          <ToggleButton key={option.value} value={option.value}>
            <Typography
              text={option.label}
              fontSize="0.8rem"
              ellipsisText={true}
            />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledWrapper>
  );
};

export default MuiToggleButton;

const StyledWrapper = styled.div<{ isTablet: boolean }>`
  .Mui-selected {
    color: ${colors.grey[900]};
    background-color: ${colors.grey[200]};
  }
  .MuiToggleButton-root {
    text-transform: none;
    width: max-content;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${({ isTablet }) =>
    isTablet &&
    css`
      width: 100%;
      .MuiToggleButton-root {
        flex-grow: 1;
      }
    `}
`;
