import React, { ReactNode, useMemo, useState } from 'react';
import { colors } from '../../assets/colors';
import styled from 'styled-components';
import PaymentBanner from './payment-banner';
import HeaderWithSampleButton from '../Header-with-button';
import { useLocation } from 'react-router-dom';
import { routeNames } from '../../constants/routesPath';
import Banner from '../common/banner';
import Typography from '../typography';
import EtsyProductionPartnerModal from '../common/etsy-production-partner-modal';
import { useAppSelector } from '../../store/hooks';
import { AuthInitialState, getUserData } from '../../store/slices/authSlice';

interface Props {
  children: ReactNode;
}
const BaseLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const { userInfo }: AuthInitialState = useAppSelector(getUserData);

  const title = useMemo(() => {
    switch (location.pathname) {
      case routeNames.myProducts:
        return 'My Products';
      case routeNames.orders:
        return 'Orders';
      case routeNames.catalog:
        return 'Catalog';
      default:
        return '';
    }
  }, [location.pathname]);

  const [openProductionPartnerModal, setOpenProductionPartnerModal] =
    useState<boolean>(false);
  return (
    <StyledWrapper>
      {/* Payment Banner */}

      <PaymentBanner />

      {/* Etsy Production Partner */}

      {!userInfo?.isProductionPartnerCreated && (
        <>
          <Banner
            text={
              <Typography
                text={
                  <>
                    We have noticed that EverBee isn't listed as a production
                    partner in your shop.{' '}
                    <span
                      className="underline-text"
                      onClick={() => setOpenProductionPartnerModal(true)}
                    >
                      Follow these quick steps
                    </span>{' '}
                    to set it up seamlessly.
                  </>
                }
                fontSize="0.9rem"
                secondaryColorValue={900}
              />
            }
            type="warning"
          />
          {openProductionPartnerModal && (
            <EtsyProductionPartnerModal
              onClose={() => setOpenProductionPartnerModal(false)}
            />
          )}
        </>
      )}
      <HeaderWithSampleButton title={title} />

      {children}
    </StyledWrapper>
  );
};

export default BaseLayout;

const StyledWrapper = styled.div`
  .underline-text {
    border-bottom: 1px solid ${colors.hyperLink};
    cursor: pointer;
    color: ${colors.hyperLink};
  }
`;
