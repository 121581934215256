export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    900: '#19191A',
    800: '#313233',
    700: '#505152',
    600: '#646566',
    500: '#8B8B8C',
    400: '#CCCCCC',
    300: '#E6E6E6',
    200: '#F2F2F2',
    150: '#F7F7F7',
    100: '#FAFAFA',
    50: '#F0F0F0',
    border: '#EBEBEB',
  },
  green: {
    900: '#4AA659',
    600: '#66B173',
    300: '#75B981',
  },
  red: {
    900: '#DC3C36',
    600: '#E55550',
    300: '#F1706C',
    100: '#FFE8E7',
  },
  blue: {
    900: '#1F76CC',
    800: '#2E8AE5',
    700: '#3D98F2',
    600: '#55A4F2',
    500: '#6FB4F7',
    400: '#89C2FB',
    300: '#A5D2FE',
    200: '#CCE6FF',
    100: '#F3F9FF',
  },
  orange: {
    900: '#F1651F',
    500: '#FF9751',
    200: '#FFC983',
    100: '#FFEEE3',
  },
  yellow: '#FFC738',
  hyperLink: '#0000ee',
  background: ' #f5f5f5',
  shadows: {
    elevation1:
      '0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
    elevation2:
      '0px 1px 1px rgba(0, 0, 0, 0.18), 0px 0px 0px 1px rgba(0, 0, 0, 0.15)',
    elevation3:
      '0px 1px 1px rgba(0, 0, 0, 0.24), 0px 0px 0px 1px rgba(0, 0, 0, 0.2)',
    elevation4:
      '0px 20px 25px -8px rgba(0, 0, 0, 0.06), 0px 8px 10px -6px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.06)',
    elevation5:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 1px 1px 0px rgba(229, 85, 80, 0.80), 0px 0px 0px 1px #E55550',
    panel1: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    panel2: '0px 1px 0px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.1)',
    panel3: ' -2px 0px 8px rgba(0, 0, 0, 0.04)',
  },
};
