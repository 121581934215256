import { CustomGridColDef } from '../../../constants/tableColumnConstants';
import {
  RenderActionButtons,
  RenderActionHeader,
  RenderInventory,
  RenderPrintProvider,
  RenderProduct,
  RenderText,
  RenderTextHeader,
  RenderTextWithColorMark,
} from '../../../utils/render-cells';

export const myProductColumns = (
  deleteProduct: (productId?: string) => void,
  publishProduct: (productId?: string) => void,
  duplicateProduct: (productId?: string) => void,
  discardChangesHandler: (productId: string) => void,
  isTablet: boolean,
  selectedRowsCount: number,
  showPublishOption: boolean,
) => {
  const columns: CustomGridColDef[] = [
    {
      field: 'productTitle',
      headerName: 'Product',
      disableReorder: true,
      disableColumnMenu: true,
      flex: 1.2,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderProduct,
      metadata: {
        sortBy: 'title',
      },
    },
    {
      field: 'printProviders',
      headerName: 'Produced by',
      disableReorder: true,
      filterable: false,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderPrintProvider,
      metadata: {
        sortBy: 'printProviderId',
      },
    },
    {
      field: 'brands',
      headerName: 'Brand',
      disableReorder: true,
      filterable: false,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: (props) => RenderText(props, '14px'),
      metadata: {
        sortBy: 'brand',
      },
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderInventory,
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderHeader: (props) => RenderTextHeader(props, '14px'),
      renderCell: RenderTextWithColorMark,
    },
    {
      field: 'actions',
      headerName: ' ',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: !isTablet ? 200 : 100,
      headerClassName: 'action-btn-header',
      renderCell: (props) => (
        <RenderActionButtons
          prop={props}
          deleteProduct={deleteProduct}
          publishProduct={publishProduct}
          duplicateProduct={duplicateProduct}
          discardChangesHandler={discardChangesHandler}
          isTablet={isTablet}
        />
      ),

      renderHeader: (props) => (
        <RenderActionHeader
          {...props}
          deleteProduct={deleteProduct}
          publishProduct={showPublishOption ? publishProduct : undefined}
          duplicateProduct={duplicateProduct}
          selectedRowsCount={selectedRowsCount}
        />
      ),
      cellClassName: 'actions',
    },
  ];
  return columns;
};
