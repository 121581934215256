import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../../common/wrappers';
import SearchBar from '../../common/search-bar';
import { SearchIcon } from '../../../assets/icons';
import { Option, ProductsFilters } from '../../../interface';
import { colors } from '../../../assets/colors';
import MuiToggleButton from '../../common/MuiToggleButton';

interface Props {
  filters: ProductsFilters;
  setFilters: React.Dispatch<React.SetStateAction<ProductsFilters>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isTablet: boolean;
}

const Header: React.FC<Props> = ({
  filters,
  setFilters,
  setCurrentPage,
  isTablet,
}) => {
  return (
    <StyledWrapper isTablet={isTablet}>
      <FlexBox
        gap="1.5rem"
        className="w-100"
        alignItems={isTablet ? 'flex-start' : 'center'}
        justifyContent={isTablet ? 'flex-start' : 'space-between'}
        direction={isTablet ? 'column' : 'row'}
      >
        <SearchBar
          className="searchbar-filter"
          onChange={(value: string) =>
            setFilters({ ...filters, search: value })
          }
          value={filters.search}
          icon={SearchIcon}
        />
        <MuiToggleButton
          options={statusOptions}
          selectedValue={filters.status}
          setSelectedValue={(value) => {
            setFilters((prev) => ({ ...prev, status: value ?? '' }));
            setCurrentPage(1);
          }}
          isTablet={isTablet}
        />
      </FlexBox>
    </StyledWrapper>
  );
};

export default Header;

const StyledWrapper = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
  border: 1px solid ${colors.grey[50]};
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: none;

  .icon-btn-wrapper {
    position: relative;
  }

  .searchbar-filter {
    width: ${({ isTablet }) => (isTablet ? '100%' : '30%')};

    input {
      /* height: 100% !important; */
      padding: 0.5rem;
    }
  }
  .drop-down-filters {
    width: 70%;
  }
  .status-filter {
    .colored-item {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }
  .clear-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${colors.grey['600']};
    background-color: ${colors.white};
    outline: none;
    border: none;
    gap: 6px;
    cursor: pointer;
  }
  .action-btns-wrapper {
    margin-left: auto;
  }
  .delete-btn {
    background-color: ${colors.red[900]};
    margin-left: auto;
  }
  .products-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 13px;
    margin: 0;
    padding-left: 12px;
  }
`;

const statusOptions: Array<Option> = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Published',
    value: 'Published',
  },
  {
    label: 'Unpublished changes',
    value: 'Unpublished changes',
  },
  {
    label: 'Unpublished',
    value: 'Unpublished',
  },
  {
    label: 'Draft',
    value: 'Draft',
  },
];
