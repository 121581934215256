import React from 'react';

const CustomSortIcon: React.FC<{ isDescending?: boolean }> = ({
  isDescending,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isDescending ? (
        <g transform="rotate(180, 8, 8)">
          <path
            d="M11 9L8 12L5 9"
            stroke="#505152"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 12V4"
            stroke="#505152"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      ) : (
        <>
          <path
            d="M11 9L8 12L5 9"
            stroke="#505152"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 12V4"
            stroke="#505152"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </svg>
  );
};

export default CustomSortIcon;
