/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { myProductColumns } from './my-product-columns';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useDeleteProductByIdMutation,
  useDicardUnpublishedChangesMutation,
  useDuplicateProductMutation,
  usePublishProductMutation,
} from '../../../store/services/products';
import Loader from '../../common/loader';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { socket } from '../../../helper/socket';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getUserData } from '../../../store/slices/authSlice';
import ConfirmationModal from '../../common/confirmation-modal';
import MuiDataTableV2 from '../../table/MuiDataTable-v2';
import { ColumnMenuFilterOptions } from '../../../interface/product-interface';
import { CustomGridColDef } from '../../../constants/tableColumnConstants';
import { ProductsFilters, Status } from '../../../interface';
import {
  getDetails,
  updateSeletedRows,
} from '../../../store/slices/detailsSlice';
interface UpdateProductStatus {
  productId: string;
  status: string;
}

interface DefaultImageUploadedSocketMessage {
  productId: string;
  defaultImageUpdated: boolean;
  url: string;
}

interface Props {
  rowData: any[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  currentPage: number;
  refetchProduct: () => void;
  isTablet: boolean;
  totalRows?: number;
  setSelectedFilterOptions: React.Dispatch<
    React.SetStateAction<{
      [field: string]: string[];
    }>
  >;
  selectedFilterOptions: {
    [field: string]: string[];
  };
  filterOptions: ColumnMenuFilterOptions;
  isLoading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<ProductsFilters>>;
  filters: ProductsFilters;
}
const MyProductsTable: React.FC<Props> = ({
  rowData,
  setCurrentPage,
  currentPage,
  totalPages,
  refetchProduct,
  isTablet,
  totalRows,
  filterOptions,
  selectedFilterOptions,
  setSelectedFilterOptions,
  isLoading,
  filters,
  setFilters,
}) => {
  const [productInfo, setProductInfo] = useState<UpdateProductStatus>({
    productId: '',
    status: '',
  });
  const dispatch = useAppDispatch();
  const { selectedRows } = useAppSelector(getDetails);
  const location = useLocation();
  const { userInfo } = useAppSelector(getUserData);
  const navigate = useNavigate();

  const [productId, setProductId] = useState<string | null>(null);
  const [defaultDesignUpdates, setDefaultDesignUpdates] = useState<
    DefaultImageUploadedSocketMessage[]
  >([]);
  const [fromTopBar, setFromTopBar] = useState(
    location.state?.fromTopBar || false,
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState<
    'publish' | 'delete' | 'failedToPublish' | null
  >(null);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [unpublishedProducts, setUnpublishedProducts] = useState<string[]>([]);

  const [duplicateProduct, { isLoading: isDuplicating }] =
    useDuplicateProductMutation();
  const [dicardUnpublishedChanges, { isLoading: isDiscardingChanges }] =
    useDicardUnpublishedChangesMutation();
  const apiRef = useGridApiRef();

  const [deleteProduct, { isLoading: isDeleting }] =
    useDeleteProductByIdMutation();
  const [publishProducts, { isLoading: isPublishing, error: publishingError }] =
    usePublishProductMutation();

  const publishProductHandler = async (productId?: string) => {
    if (productId) {
      await publishProducts({ productIds: [productId] });
    } else {
      setOpenConfirmationModal('publish');
    }
  };

  const mergeDefaultImageUpdatesAndRowData = () => {
    return rowData.map((row) => {
      const isDefaultDesignUpdateAvailable = defaultDesignUpdates?.find(
        (e) => e.productId === row.id,
      );
      if (isDefaultDesignUpdateAvailable) {
        if (row.images) {
          return {
            ...row,
            savingDesign: false,
            image: row.images[0].imageUrl,
          };
        } else {
          return {
            ...row,
            savingDesign: false,
            image: isDefaultDesignUpdateAvailable.url,
          };
        }
      }
      return row;
    });
  };

  const updateDefaultImageInRowData = (
    data: DefaultImageUploadedSocketMessage,
  ) => {
    setDefaultDesignUpdates((prevArray) => [...(prevArray || []), data]);
  };

  const duplicateHandler = async (productId?: string) => {
    const idsToDuplicate = productId ? [productId] : selectedRows;

    if (!idsToDuplicate || idsToDuplicate.length === 0) {
      return;
    }

    await duplicateProduct(idsToDuplicate);
    dispatch(updateSeletedRows([]));

    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      refetchProduct();
    }
  };

  const deleteProductHandler = (productId?: string) => {
    if (productId) {
      setProductId(productId);
    } else {
      setOpenConfirmationModal('delete');
    }
  };

  const discardChangesHandler = async (productId: string) => {
    await dicardUnpublishedChanges(productId).unwrap();
  };

  const handleRowsScrollEnd = () => {
    if (isLoading || currentPage + 1 > totalPages) return;
    setCurrentPage((prev) => prev + 1);
  };

  const handleSortChange = (model: GridSortModel) => {
    const sortInfo = model[0] || { field: 'updatedAt', sort: 'desc' };

    const fieldName =
      (apiRef.current.getColumn(sortInfo.field) as CustomGridColDef)?.metadata
        ?.sortBy || sortInfo.field;

    setFilters((prev) => ({
      ...prev,
      sortBy: {
        field: fieldName,
        sort: sortInfo.sort,
      },
    }));
    setCurrentPage(1);
  };

  useEffect(() => {
    socket.emit('join', userInfo.id);

    const onMockupsUploaded = (data: UpdateProductStatus) => {
      setProductInfo(data);
    };
    socket.on('defaultImageUploaded', updateDefaultImageInRowData);
    socket.on('imagesUploadedToEtsy', onMockupsUploaded);
    return () => {
      socket.off('imagesUploadedToEtsy', onMockupsUploaded);
      socket.off('defaultImageUploaded', updateDefaultImageInRowData);
    };
  }, []);

  useEffect(() => {
    if (productInfo.productId && rowData.length > 0) {
      const foundProduct = rowData.find(
        (product) => product.id === productInfo.productId,
      );
      if (foundProduct) {
        apiRef.current.updateRows([
          ...rowData,
          { ...foundProduct, status: productInfo.status },
        ]);
      }
    }
  }, [productInfo, rowData]);

  useEffect(() => {
    if (refetchProduct && totalPages !== 1 && rowData.length === 0) {
      refetchProduct();
    }
    if (fromTopBar && rowData.length > 0) {
      refetchProduct();
      setFromTopBar(false);
      navigate(location.pathname, { state: { fromTopBar: false } });
    }
  }, [rowData, refetchProduct]);

  useEffect(() => {
    const error = publishingError as any;
    if (error?.data?.productIdsFailedToPublish) {
      const { productIdsFailedToPublish } = error.data;
      const productIds = productIdsFailedToPublish.map(
        (productObj: any) => productObj.productId,
      );
      setRetryCount((error.data?.retryCount ?? retryCount) + 1);
      dispatch(updateSeletedRows(productIds));
      setUnpublishedProducts(productIds);
      setOpenConfirmationModal('failedToPublish');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, publishingError]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const unpublishedIds = selectedRows.filter((id: string) => {
        const row = rowData.find((row) => row.id === id);
        return (
          row &&
          (row.status === Status.Unpublished ||
            row.status === Status.UnpublishedChanges)
        );
      });

      setUnpublishedProducts(unpublishedIds);
    }
  }, [rowData, selectedRows]);

  return (
    <StyledWrapper isTablet={isTablet}>
      {(isDeleting || isPublishing || isDuplicating || isDiscardingChanges) && (
        <Loader />
      )}
      <MuiDataTableV2
        columns={myProductColumns(
          deleteProductHandler,
          publishProductHandler,
          duplicateHandler,
          discardChangesHandler,
          isTablet,
          selectedRows.length,
          unpublishedProducts.length > 0,
        )}
        rowData={mergeDefaultImageUpdatesAndRowData()}
        rowHeight={100}
        rowClickHandler={(params) => navigate(`/details/${params.id}`)}
        apiRef={apiRef}
        totalRows={totalRows}
        filterOptionsByfieldName={filterOptions}
        onFilterApply={(field, values) => {
          setSelectedFilterOptions((prev) => ({ ...prev, [field]: values }));
          setCurrentPage(1);
        }}
        selectedFilters={selectedFilterOptions}
        isLoading={isLoading}
        handleRowsScrollEnd={handleRowsScrollEnd}
        onSortModelChange={handleSortChange}
      />

      {(productId || openConfirmationModal === 'delete') && (
        <ConfirmationModal
          onClose={() => {
            setProductId(null);
            setOpenConfirmationModal(null);
          }}
          text={
            productId || selectedRows.length === 1
              ? 'Are you sure you want to delete this product?'
              : `Are you sure you want to delete these ${selectedRows.length} products?`
          }
          buttonColor="red"
          headingText="Delete"
          buttonLabel="Delete"
          onClick={async () => {
            const idsToDelete = productId ? [productId] : selectedRows;
            await deleteProduct(idsToDelete);
          }}
        />
      )}

      {/* confirmation modal for publish products */}
      {(openConfirmationModal === 'publish' ||
        openConfirmationModal === 'failedToPublish') && (
        <ConfirmationModal
          onClose={() => {
            setOpenConfirmationModal(null);
          }}
          buttonColor="primary"
          headingText="Confirmation"
          buttonLabel="Publish"
          text={
            retryCount === 2
              ? 'Please contact the customer support for assistance.'
              : openConfirmationModal === 'failedToPublish'
              ? 'Some listings failed to publish. Please review the following items and try again, If the problem persists, contact the custome support for assistance.'
              : 'Are you sure you want to publish all selected unpublished / unpublished changes listings?'
          }
          hideButtons={retryCount === 2}
          onClick={async () => {
            await publishProducts({
              productIds: unpublishedProducts,
              retryCount,
            });
          }}
        >
          {openConfirmationModal === 'failedToPublish' && (
            <ul className="products-info-wrapper">
              {rowData
                .filter((data) => unpublishedProducts.includes(data.id))
                .map((data) => (
                  <li key={data.id}>{data.productTitle}</li>
                ))}
            </ul>
          )}
        </ConfirmationModal>
      )}
    </StyledWrapper>
  );
};

export default MyProductsTable;

const StyledWrapper = styled.div<{ isTablet: boolean }>`
  max-height: 810px;
  width: 100%;
  height: ${(props) => (props.isTablet ? '67vh' : '62vh')};
  .info-text {
    display: inline-block;
    width: 100%;
    text-align: end;
    padding-bottom: 10px;
  }
`;
