import { useGLTF, Environment, Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  MutableRefObject,
} from 'react';
import * as THREE from 'three';
import { MeshConstants, MugModels } from './utils';
import { MugType } from '../../../../interface/product-interface';
import Loader from '../../../common/loader';
import { useLocation } from 'react-router-dom';
import { routeNames } from '../../../../constants/routesPath';
import { Provider } from 'react-redux';
import { store } from '../../../../store';

interface selectedColorProps {
  colorHex: string;
  label: string;
  value: string;
}
interface MugProps {
  rotationY: number;
  image: Blob | string;
  selectedColor: selectedColorProps;
  isColoredMug: boolean;
  snapClicked?: any;
  setSnapImages?: any;
  frontDesign?: string;
  model: '15OZ' | '11OZ';
  isBlackMug: boolean | undefined;
}

const Mug3d = forwardRef((props: MugProps, ref) => {
  const {
    setSnapImages,
    rotationY,
    image,
    isColoredMug,
    selectedColor,
    frontDesign,
    model,
    isBlackMug,
  } = props;
  const { nodes, materials, scene } = useGLTF(
    `/models/${isBlackMug && model === '11OZ' ? `${model}Black` : model}.glb`,
  );

  const { camera, gl, scene: threeScene, size } = useThree();
  const [texture, setTexture] = useState<THREE.Texture | null>(null);
  const groupRef = useRef<THREE.Group | null>(
    null,
  ) as MutableRefObject<THREE.Group | null>;
  const [isRendered, setIsRendered] = useState(true);
  const { pathname } = useLocation();
  useEffect(() => {
    const processImage = (
      imageSrc: string,
      backgroundColor: string,
      callback: (result: string) => void,
    ) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) return;
        const extraPadding = 60;
        const leftRightPadding = 300;
        canvas.width =
          img.width +
          (isBlackMug && model === '15OZ' ? 2 * leftRightPadding : 0);
        canvas.height =
          img.height + (isBlackMug && model === '15OZ' ? 2 * extraPadding : 0);

        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        if (isBlackMug && model === '15OZ') {
          ctx.drawImage(
            img,
            leftRightPadding,
            extraPadding,
            img.width,
            img.height,
          );
        } else {
          ctx.drawImage(img, 0, 0);
        }
        try {
          callback(canvas.toDataURL());
        } catch (error) {}
      };
    };

    if (image instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          processImage(
            e.target.result as string,
            isBlackMug ? 'black' : 'white',
            (processedImage) => {
              const img = new Image();
              img.src = processedImage;
              img.onload = () => {
                const newTexture = new THREE.Texture(img);
                newTexture.colorSpace = THREE.SRGBColorSpace;
                newTexture.needsUpdate = true;
                setTexture(newTexture);
              };
            },
          );
        }
      };
      reader.readAsDataURL(image);
    } else {
      const loader = new THREE.TextureLoader();
      loader.load(
        image,
        (loadedTexture) => {
          const img = new Image();
          img.src = loadedTexture.image.src;

          img.onload = () => {
            processImage(
              loadedTexture.image.src,
              isBlackMug ? 'black' : 'white',
              (processedImage) => {
                const processedLoader = new THREE.TextureLoader();
                processedLoader.load(processedImage, (newTexture) => {
                  newTexture.colorSpace = THREE.SRGBColorSpace;
                  newTexture.needsUpdate = true;
                  setTexture(newTexture);
                });
              },
            );
          };
        },
        undefined,
        (error) => {},
      );
    }
  }, [frontDesign, image, isBlackMug, isColoredMug, model]);

  useEffect(() => {
    if (scene) {
      // Add a Hemisphere light for soft, even lighting across the model
      const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 10);

      hemisphereLight.position.set(10, 10, 10); // Place it above the model
      // scene.add(hemisphereLight);

      // Optional: You can still keep a low-intensity directional light for subtle shadows
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(5, 5, 5).normalize();
      directionalLight.castShadow = false;
      directionalLight.shadow.mapSize.width = 1024;
      directionalLight.shadow.mapSize.height = 1024;
      // scene.add(directionalLight);
      const key = isBlackMug ? `${model}Black` : model;
      const { colorMeshes, textureMesh } =
        MeshConstants[key as keyof typeof MeshConstants];

      scene.traverse((object) => {
        if ((object as THREE.Mesh).isMesh) {
          const mesh = object as THREE.Mesh;
          if (colorMeshes.includes(mesh.name)) {
            const material = mesh.material as THREE.MeshStandardMaterial;
            if (!isColoredMug) {
              material.color = new THREE.Color(0xffffff);
            } else {
              const colorName = `#${(
                selectedColor?.value ?? selectedColor.colorHex
              )
                .split('/')[1]
                .trim()
                .replace(/\s+/g, '')
                .toLowerCase()}`;
              material.color = new THREE.Color(colorName);
              material.needsUpdate = true;
            }
            if (isBlackMug && model === '15OZ') {
              material.color = new THREE.Color(0x000000);
            }
          }
          if (mesh.name === textureMesh) {
            const material = mesh.material as THREE.MeshStandardMaterial;
            if (material && texture) {
              material.map = texture;
              material.map.flipY = false;
              material.needsUpdate = true;
              setIsRendered(false);
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColoredMug, selectedColor, scene, texture, isBlackMug]);

  useImperativeHandle(ref, () => ({
    takeScreenshot: () => {
      if (!groupRef.current) return;
      const renderer = gl as THREE.WebGLRenderer;
      const originalPixelRatio = renderer.getPixelRatio();
      const originalSize = renderer.getSize(new THREE.Vector2());
      const boundingBox = new THREE.Box3().setFromObject(groupRef.current);
      const center = boundingBox.getCenter(new THREE.Vector3());
      const originalCameraPosition = camera.position.clone();
      const originalCameraTarget = center.clone();
      const originalClearColor = renderer.getClearColor(new THREE.Color());
      const zoomFactor = 0.8;

      camera.position.set(
        center.x + (camera.position.x - center.x) * zoomFactor,
        center.y + (camera.position.y - center.y) * zoomFactor,
        center.z + (camera.position.z - center.z) * zoomFactor,
      );
      camera.lookAt(center);
      renderer.setClearColor(new THREE.Color(0xffffff), 1);
      renderer.render(threeScene, camera);

      renderer.setPixelRatio(window.devicePixelRatio * 2.6);
      renderer.setSize(size.width * 2.6, size.height * 2.6);

      renderer.render(threeScene, camera);
      const dataURL = renderer.domElement.toDataURL();

      renderer.setPixelRatio(originalPixelRatio);
      renderer.setSize(originalSize.width, originalSize.height);
      renderer.setClearColor(originalClearColor, 0);
      camera.position.copy(originalCameraPosition);
      camera.lookAt(originalCameraTarget);

      setSnapImages(dataURL, props.selectedColor.label);
    },
  }));

  useEffect(() => {
    const group = groupRef.current;
    const modelPath = `/models/${
      isBlackMug && model === '11OZ' ? `${model}Black` : model
    }.glb`;

    return () => {
      // Clean up resources
      if (group) {
        group.traverse((object) => {
          if ((object as THREE.Mesh).isMesh) {
            const mesh = object as THREE.Mesh;
            mesh.geometry.dispose();
            if (Array.isArray(mesh.material)) {
              mesh.material.forEach((material) => material.dispose());
            } else if (mesh.material) {
              mesh.material.dispose();
            }
          }
        });
        groupRef.current = null;
      }
      scene.traverse((object) => {
        if ((object as THREE.Mesh).isMesh) {
          const mesh = object as THREE.Mesh;
          mesh.geometry.dispose();
          if (mesh.material instanceof THREE.Material) {
            mesh.material.dispose();
          }
        }
      });
      useGLTF.clear(modelPath);
      THREE.Cache.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMugMeshes = (
    model: '15OZ' | '11OZ' | '11OZBlack',
    nodes: any,
    materials: any,
    scale?: [number, number, number],
  ) => {
    const meshes = MugModels[model].map(
      ({ geometryKey, materialKey }, index) => (
        <mesh
          key={index}
          castShadow={false}
          receiveShadow={false}
          geometry={(nodes[geometryKey] as THREE.Mesh).geometry}
          material={materials[materialKey]}
        />
      ),
    );

    if (scale) {
      return <group scale={scale}>{meshes}</group>;
    }

    return <>{meshes}</>;
  };

  return (
    <>
      {!isRendered ? (
        <>
          <Environment preset="city" />

          <group
            ref={groupRef}
            rotation={[0, (rotationY * Math.PI) / 180, 0]}
            {...props}
            dispose={null}
          >
            {model === MugType._15oz
              ? getMugMeshes(model, nodes, materials, [0.6174, 0.6881, 0.6174])
              : getMugMeshes(
                  isBlackMug ? '11OZBlack' : model,
                  nodes,
                  materials,
                )}
          </group>
        </>
      ) : (
        <Html>
          <Provider store={store}>
            {pathname.includes(routeNames.editor) && (
              <Loader disableBackDrop={true} />
            )}
          </Provider>
        </Html>
      )}
    </>
  );
});

export default Mug3d;
