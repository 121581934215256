import React, { useEffect, useMemo, useState } from 'react';
import { GridColumnMenuProps } from '@mui/x-data-grid-pro';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import SearchBar from '../common/search-bar';
import { SearchIcon } from '../../assets/icons';
import { FlexBox } from '../common/wrappers';
import Typography from '../typography';
import Checkbox from '../checkbox';
import Button from '../common/button';
import { Option } from '../../interface';
import { useDebounce } from '../../hooks/useDebounce';
import { ReactComponent as NoResultsFoundIcon } from '../../assets/images/no-results-found.svg';

interface Props extends GridColumnMenuProps {
  options: Option[];
  selectedFilters: string[];
  onApply: (values: string[]) => void;
}

const CustomColumnMenu: React.FC<Props> = ({
  selectedFilters,
  options,
  onApply,
  hideMenu,
  colDef,
}) => {
  const [localFilters, setLocalFilters] = useState<string[]>(selectedFilters);
  const [searchText, setSearchText] = useState<string>('');

  const debouncedValue = useDebounce(searchText, 200);

  useEffect(() => {
    setLocalFilters(selectedFilters);
  }, [selectedFilters]);

  const handleChange = (option: string) => {
    const updatedFilters = localFilters.includes(option)
      ? localFilters.filter((item) => item !== option)
      : [...localFilters, option];

    setLocalFilters(updatedFilters);
  };

  const filterOptions = useMemo(() => {
    if (!debouncedValue) return options;
    return options.filter((o) =>
      o.label.toLocaleLowerCase().includes(debouncedValue.toLocaleLowerCase()),
    );
  }, [debouncedValue, options]);

  const handleSelectAll = () => {
    if (filterOptions.length === 0) return;

    const values = filterOptions
      .map((o) => o.value)
      .filter((o) => !localFilters.includes(o.value));

    setLocalFilters(values);
  };

  return (
    <CustomColumnMenuWrapper>
      <div className="search-wrapper">
        <SearchBar
          onChange={(value) => setSearchText(value)}
          value={searchText}
          icon={SearchIcon}
        />
      </div>

      <FlexBox className="content-wrapper" direction="column">
        <FlexBox
          justifyContent="space-between"
          alignItems="center"
          className="w-100 "
        >
          <Typography
            text={`Select all (${filterOptions.length})`}
            fontSize="0.75rem"
            className={
              filterOptions.length === 0 ? 'disable-select' : 'pointer-cursor'
            }
            primaryColorValue={900}
            onClick={handleSelectAll}
          />
          <Typography
            className={`red-color ${
              localFilters.length === 0 ? 'disable-select' : 'pointer-cursor'
            }`}
            text={`Clear selected (${localFilters.length})`}
            fontSize="0.75rem"
            onClick={() => setLocalFilters([])}
          />
        </FlexBox>
        <FlexBox gap="0.5rem" direction="column" className="filter-options">
          {filterOptions.map((obj) => (
            <Checkbox
              key={obj.value}
              onChange={() => handleChange(obj.value)}
              label={obj.label}
              checked={localFilters.includes(obj.value)}
            />
          ))}
        </FlexBox>
        {filterOptions.length === 0 && (
          <FlexBox
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            className="w-100"
          >
            <NoResultsFoundIcon />
            <Typography text="No results found" fontSize="1rem" />
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        className="btns-wrapper"
        justifyContent="space-between"
        gap="0.5rem"
      >
        <Button
          label="Cancel"
          size="fullWidth"
          appearance="secondary"
          onClick={(e) => {
            setLocalFilters([]);
            onApply([]);
            hideMenu(e);
          }}
        />
        <Button
          label="Apply"
          size="fullWidth"
          appearance="primary"
          onClick={(e) => {
            onApply(localFilters);
            hideMenu(e);
          }}
        />
      </FlexBox>
    </CustomColumnMenuWrapper>
  );
};

export default CustomColumnMenu;

const CustomColumnMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  max-width: 20rem;

  .search-wrapper {
    padding: 1rem;
    background-color: ${colors.grey[100]};
  }
  .content-wrapper {
    display: flex;
    padding: 0.75rem 0.75rem 1rem 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;

    .filter-options {
      max-height: 8rem;
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
    .filter-options::-webkit-scrollbar {
      width: 6px; /* Adjust the scrollbar width */
    }
    .filter-options::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 1rem;
    }
    .filter-options::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }
    .filter-options::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 1rem;
    }
  }

  .btns-wrapper {
    box-sizing: border-box;
    padding: 0.75rem;
    width: 100%;
    border-top: 0.5px solid ${colors.grey[50]};
  }

  .MuiDataGrid-menu .MuiPaper-root {
    border-radius: 0.5rem !important;
  }

  .disable-select {
    opacity: 0.4;
    cursor: default;
  }
`;
