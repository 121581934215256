import React from 'react';
import { DropIcon } from '../../assets/icons';
import styled from 'styled-components';

const DropzoneOverlay = () => {
  return (
    <StyledWrapper className="drag-overlay">
      <img src={DropIcon} alt="draganddrop" />

      <p className="fs-28 fw-700">Drop your images here</p>
    </StyledWrapper>
  );
};

export default DropzoneOverlay;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1120;
  backdrop-filter: blur(3px);
  border: 1px solid #3d98f2;
`;
